import { Entities } from '@vestiaire/api-specification';
import { Action, InferAction, WithStatusType } from '../../types';
import Checkout from '@adyen/adyen-web/dist/types/core';
import { PaymentMethodOptions } from '@adyen/adyen-web/dist/types/types';
import { FlowType, Intent } from 'paypal-checkout-components';

export type AdyenCheckout = Checkout;

export type OldPaymentMethod = Entities.PaymentMethod;
export type OrderPaymentMethod = Entities.PaymentPaymentMethod;

export type AdyenPayGenericRequest = {
  paymentId: string;
  returnUrl: string;
  paymentMethod: OrderPaymentMethod;
  browserInfo: Record<string, any>;
};

export interface AdyenPayCreditCardRequest extends AdyenPayGenericRequest {
  saveCard: boolean;
}

export interface AdyenPayGenericResponse extends Entities.Payment {
  // meta is optionnal on Entities.Payment while This is mandatory for adyen
  meta: {
    action?: {
      method: 'GET' | 'POST';
      paymentData: string;
      paymentMethodType: string;
      type: 'redirect' | 'voucher' | 'qrCode' | 'await' | 'sdk' | 'threeDS2Fingerprint' | 'threeDS2Challenge';
      url: string;
      data: {
        [key: string]: string;
      };
    };
    details?: any;
    [key: string]: any;
  };
}

export type PayloadAdyenHandleResultCode = {
  orderId?: string;
  orderAmount?: string;
  adyenResponse: AdyenPayGenericResponse;
};

export type AdyenConfirmGenericRequest = {
  paymentId: string;
  details: any;
};

export type AdyenConfirmGenericResponse = AdyenPayGenericResponse;

export enum Mnemonic {
  AFFIRM = 'affirm', // ===> no psp but a specific gateway
  ALIPAY = 'alipay',
  ALIPAY_HK = 'alipay_hk',
  APPLE_PAY = 'apple_pay',
  BANCONTACT = 'bancontact_no_hpp',
  CREDIT_CARD = 'credit_card',
  GOOGLE_PAY = 'google_pay',
  IDEAL = 'ideal_no_hpp',
  KCP_BANKTRANSFER = 'kcp_banktransfer',
  KLARNA_FINANCING = 'klarna_financing',
  KLARNA_PAY_LATER = 'klarna_pay_later',
  KOREA_CYBER_PAYMENT = 'korea_cyber_payment',
  NAVER_PAY = 'naver_pay',
  ONEY_3X = 'oney_3x',
  ONEY_4X = 'oney_4x',
  PAYPAL = 'paypal',
  SOFORT = 'sofort',
  SPLITIT = 'splitit', // ===> no psp but a specific gateway
  VENMO = 'venmo',
}

export enum AdyenPaymentMnemonic {
  ALIPAY = 'alipay',
  ALIPAY_HK = 'alipay_hk',
  BANCONTACT = 'bcmc',
  CREDIT_CARD = 'scheme',
  IDEAL = 'ideal',
  KCP_BANKTRANSFER = 'kcp_banktransfer',
  KLARNA_FINANCING = 'klarna_account',
  KLARNA_PAY_LATER = 'klarna',
  KOREA_CYBER_PAYMENT = 'kcp_creditcard',
  NAVER_PAY = 'kcp_naverpay',
  ONEY_3X = 'facilypay_3x',
  ONEY_4X = 'facilypay_4x',
  SOFORT = 'directEbanking',
  STORED_CARD = 'stored_card',
}

export enum ActionTypes {
  // NOTA BENE : payment events are declared in the order they should be called
  FETCH_ORDER_PAYMENT_METHODS_REQUEST = 'payment/orderPaymentMethods/request',
  FETCH_ORDER_PAYMENT_METHODS_RESOLVE = 'payment/orderPaymentMethods/resolve',
  FETCH_ORDER_PAYMENT_METHODS_REJECT = 'payment/orderPaymentMethods/reject',
  RESET_ORDER_PAYMENT_METHODS = 'payment/orderPaymentMethods/reset to idle status',
  SELECT_PAYMENT_METHOD_REQUEST = 'payment/selectPaymentMethod/request',
  SELECT_PAYMENT_METHOD_RESOLVE = 'payment/selectPaymentMethod/resolve',
  SELECT_PAYMENT_METHOD_REJECT = 'payment/selectPaymentMethod/reject',
  INIT_GENERIC_GATEWAY_REQUEST = 'payment/initGenericGatewayProvider/request',
  INIT_GENERIC_GATEWAY_RESOLVE = 'payment/initGenericGatewayProvider/resolve',
  INIT_GENERIC_GATEWAY_REJECT = 'payment/initGenericGatewayProvider/reject',
  INIT_NON_GENERIC_GATEWAY_REQUEST = 'payment/initNonGenericGatewayProvider/request',
  INIT_NON_GENERIC_GATEWAY_RESOLVE = 'payment/initNonGenericGatewayProvider/resolve',
  INIT_NON_GENERIC_GATEWAY_REJECT = 'payment/initNonGenericGatewayProvider/reject',
  RESOLVE_GATEWAY = 'payment/initGateway/resolve',
  INITIALIZE_ADYEN_CHECKOUT_REQUEST = 'payment/adyen/initialization/request',
  INITIALIZE_ADYEN_CHECKOUT_RESOLVE = 'payment/adyen/initialization/resolved',
  INITIALIZE_ADYEN_CHECKOUT_REJECT = 'payment/adyen/initialization/rejected',
  INIT_PAYMENT = 'payment/userClickedOnPayButton',
  PAY_ADYEN_GENERIC_REQUEST = 'payment/adyen/generic/request',
  PAY_NON_GENERIC_REQUEST = 'payment/non generic/request',
  PAY_ADYEN_CREDIT_CARD_REQUEST = 'payment/adyen/creditcard/request',
  GET_ADYEN_REDIRECTION_DATA = 'payment/adyen/redirection/getData/init',
  GET_ADYEN_REDIRECTION_DATA_RESOLVE = 'payment/adyen/redirection/getData/resolve',
  GET_ADYEN_REDIRECTION_DATA_REJECT = 'payment/adyen/redirection/getData/reject',
  INIT_ADYEN_CONFIRMATION_REQUEST = 'payment/adyen/initConfirmation/request',
  INIT_ADYEN_CONFIRMATION_RESOLVE = 'payment/adyen/initConfirmation/resolve',
  INIT_ADYEN_CONFIRMATION_REJECT = 'payment/adyen/initConfirmation/reject',
  PAY_ADYEN_HANDLE_RESULT_CODE = 'payment/adyen/checkResultCode',
  RESOLVE_PAYMENT = 'payment/resolvePayment',
  REJECT_PAYMENT = 'payment/rejectPayment',
  REFUSE_PAYMENT = 'payment/refusePayment',
  CANCEL_PAYMENT = 'payment/cancelPayment',
  RESET_PAYMENT = 'payment/resetPayment',
  RESET_PAYMENT_STATUS_REQUEST = 'payment/resetPaymentStatusRequest',
  RESET_PAYMENT_STATUS_RESOLVE = 'payment/resetPaymentStatusResolve',
}

export enum GatewayEnums {
  ADYEN = 'adyen',
  BRAINTREE = 'braintree',
  SPLITIT = 'splitit',
  AFFIRM = 'affirm',
}

export type PayloadTypes = {
  [ActionTypes.FETCH_ORDER_PAYMENT_METHODS_REQUEST]: undefined;
  [ActionTypes.FETCH_ORDER_PAYMENT_METHODS_RESOLVE]: OrderPaymentMethod[];
  [ActionTypes.FETCH_ORDER_PAYMENT_METHODS_REJECT]: undefined;
  [ActionTypes.RESET_ORDER_PAYMENT_METHODS]: undefined;
  [ActionTypes.SELECT_PAYMENT_METHOD_REQUEST]: OrderPaymentMethod;
  [ActionTypes.SELECT_PAYMENT_METHOD_RESOLVE]: OrderPaymentMethod;
  [ActionTypes.SELECT_PAYMENT_METHOD_REJECT]: undefined;
  [ActionTypes.INIT_GENERIC_GATEWAY_REQUEST]: string;
  [ActionTypes.INIT_GENERIC_GATEWAY_RESOLVE]: GatewayInfo;
  [ActionTypes.INIT_GENERIC_GATEWAY_REJECT]: undefined;
  [ActionTypes.INIT_NON_GENERIC_GATEWAY_REQUEST]: string;
  [ActionTypes.INIT_NON_GENERIC_GATEWAY_RESOLVE]: string;
  [ActionTypes.INIT_NON_GENERIC_GATEWAY_REJECT]: undefined;
  [ActionTypes.RESOLVE_GATEWAY]: undefined;
  [ActionTypes.INITIALIZE_ADYEN_CHECKOUT_REQUEST]: Record<string, any>;
  [ActionTypes.INITIALIZE_ADYEN_CHECKOUT_RESOLVE]: AdyenCheckout;
  [ActionTypes.INITIALIZE_ADYEN_CHECKOUT_REJECT]: undefined;
  [ActionTypes.PAY_ADYEN_GENERIC_REQUEST]: AdyenPayGenericRequest;
  [ActionTypes.PAY_NON_GENERIC_REQUEST]: string;
  [ActionTypes.PAY_ADYEN_CREDIT_CARD_REQUEST]: AdyenPayCreditCardRequest;
  [ActionTypes.GET_ADYEN_REDIRECTION_DATA]: URLSearchParams;
  [ActionTypes.GET_ADYEN_REDIRECTION_DATA_RESOLVE]: AdyenRedirectionData;
  [ActionTypes.GET_ADYEN_REDIRECTION_DATA_REJECT]: undefined;
  [ActionTypes.INIT_ADYEN_CONFIRMATION_REQUEST]: undefined;
  [ActionTypes.INIT_ADYEN_CONFIRMATION_RESOLVE]: AdyenConfirmGenericResponse;
  [ActionTypes.INIT_ADYEN_CONFIRMATION_REJECT]: undefined;
  [ActionTypes.PAY_ADYEN_HANDLE_RESULT_CODE]: PayloadAdyenHandleResultCode;
  [ActionTypes.INIT_PAYMENT]: undefined;
  [ActionTypes.RESOLVE_PAYMENT]: { orderId: string; formattedAmount: string };
  [ActionTypes.REJECT_PAYMENT]: { message: string | null };
  [ActionTypes.REFUSE_PAYMENT]: { message: string | null };
  [ActionTypes.CANCEL_PAYMENT]: undefined;
  [ActionTypes.RESET_PAYMENT_STATUS_REQUEST]: undefined;
  [ActionTypes.RESET_PAYMENT_STATUS_RESOLVE]: undefined;
};

export type Actions = Action<PayloadTypes>[keyof Action<PayloadTypes>];
export type InferedActions<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T>;

export type OrderPaymentMethods = WithStatusType & {
  items: OrderPaymentMethod[];
};

export type SelectedPaymentMethod = WithStatusType & {
  current: number;
  previous: number;
};

export enum AdyenRedirectionStatus {
  IDLE = 'IDLE',
  PEND_GET_DETAILS = 'PEND_GET_DETAILS',
  OK_GET_DETAILS = 'OK_GET_DETAILS',
  ERROR_GET_DETAILS = 'ERROR_GET_DETAILS',
  PEND_CONFIRM = 'PEND_CONFIRM',
  OK_CONFIRM = 'OK_CONFIRM',
  ERROR_CONFIRM = 'ERROR_CONFIRM',
}

export type AdyenDetailsCookie = {
  key: string;
  type: string;
}[];

export type AdyenRedirectionDetails = Record<string, string>;

export type State = {
  orderPaymentMethods: OrderPaymentMethods;
  selectedPayment: SelectedPaymentMethod;
  gatewayService: ServiceProvider;
  paymentStatus: StatusEnums;
  adyenRedirection: AdyenRedirection;
  errorMessage?: string | null;
};

export type AdyenRedirectionData = {
  orderId: string;
  paymentId: string;
  orderAmount: string;
  formattedDetails: any;
};

export type AdyenRedirection = {
  status: AdyenRedirectionStatus;
  data?: AdyenRedirectionData;
  resultCodeOnCheckout?: CheckoutPathResultCode;
};

export type ServiceProvider = WithStatusType & {
  name?: GatewayEnums;
  genericGateway: WithStatusType & { data?: GatewayInfo };
  nonGenericGateway: WithStatusType & { data?: string };
  adyenCheckout?: WithStatusType & { data?: AdyenCheckout };
};

export type GatewayInfo = {
  id: string;
  paymentId: string;
  remoteRef?: string;
  type: string;
  meta?: { [k: string]: any };
};

export enum StatusEnums {
  IDLE = 'IDLE',

  // GET PAYMENT METHODS LIST
  PEND_GET_LIST = 'PEND_GET_LIST',
  OK_GET_LIST = 'OK_GET_LIST',
  ERROR_GET_LIST = 'ERROR_GET_LIST',

  // SELECT A PAYMENT METHOD
  PEND_SET_ITEM = 'PEND_SET_ITEM',
  OK_SET_ITEM = 'OK_SET_ITEM',
  ERROR_SET_ITEM = 'ERROR_SET_ITEM',

  // INSTANTIATE PAYMENT SERVICE GATEWAY
  PEND_INIT_ITEM = 'PEND_INIT_ITEM',
  OK_INIT_ITEM = 'OK_INIT_ITEM',
  ERROR_INIT_ITEM = 'ERROR_INIT_ITEM',

  // REAL USER PAYMENT ATTEMPT - click on pay
  PEND_PROCESS_ITEM = 'PEND_PROCESS_ITEM',
  OK_PROCESS_ITEM = 'OK_PROCESS_ITEM',
  ERROR_PROCESS_ITEM = 'ERROR_PROCESS_ITEM', // trigger error popin
  CANCEL_PROCESS_ITEM = 'CANCEL_PROCESS_ITEM', // trigger cancel popin
  REFUSE_PROCESS_ITEM = 'REFUSE_PROCESS_ITEM', // trigger refuse popin
  RESET_PROCESS_ITEM = 'RESET_PROCESS_ITEM', // reset error status
  OK_RESET_PROCESS_ITEM = 'OK_RESET_PROCESS_ITEM', // prevent infinte loop when requesting for a new payment init
}

export enum AdyenResultCodes {
  AUTHORIZED = 'Authorised',
  CANCELLED = 'Cancelled',
  ERROR = 'Error',
  REDIRECT_SHOPPER = 'RedirectShopper',
  REFUSED = 'Refused',
  RECEIVED = 'Received',
  PENDING = 'Pending',
}

export enum CheckoutPathResultCode {
  CANCEL = 'cancel',
  REFUSE = 'refuse',
  ERROR = 'error',
}

export enum ErrorPopinStatus {
  CANCELLED = 'cancel',
  REFUSED = 'refuse',
  ERROR = 'error',
}

export type AdyenConfig = PaymentMethodOptions<'default'>;

export type SetSelectedPaymentMethodRequestType = {
  paymentMethodId: string;
  storedCardId?: string;
};

export type AdyenState = {
  data: {
    riskData: {
      clientData: string;
    };
    paymentMethod: {
      type: string;
      holderName: string;
      encryptedSecurityCode: string;
      storedPaymentMethodId: string;
      brand: string;
    };
    storePaymentMethod: boolean;
    browserInfo: {
      acceptHeader: string;
      colorDepth: number;
      language: string;
      javaEnabled: boolean;
      screenHeight: number;
      screenWidth: number;
      userAgent: string;
      timeZoneOffset: number;
    };
    origin: string;
    clientStateDataIndicator: boolean;
  };
  isValid: boolean;
};

export type InitNonGenericGatewayRequest = {
  dl: string;
  orderId: string;
  orderAmount: string;
  id_c: string;
};

export type PaypalFlowType = FlowType;
export type PaypalItent = Intent;
