import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import braintree from 'braintree-web';
import PaymentButtonLoading from '../PaymentButton/PaymentButtonLoading';
import logger from '../../../services/logger.service';
import { SubtotalsTypes } from '../../../features/subtotals';
import { OrderLine } from '../../../types';
import { useAppDispatch } from '../../../app/hooks';
import './BraintreePaymentButton.scss';
import { PaymentTypes } from '../../../features/payment';
import { uiActions } from '../../../features/ui';
import { usePaypalCartCheck, usePaypalIntegration } from '../../../features/payment/hooks';

interface BraintreePaymentButtonProps {
  clientToken: string;
  paymentMethod: PaymentTypes.OrderPaymentMethod;
  paymentToken: string;
  orderLines: OrderLine[];
  subtotals: SubtotalsTypes.State;
  cartId: string;
}

const BraintreePaymentButton: React.FC<BraintreePaymentButtonProps> = ({
  clientToken,
  paymentMethod,
  paymentToken,
  orderLines,
  subtotals,
  cartId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isBraintreeInitialized, setBraintreeInitialized] = useState(false);
  const paypalContainerRef = useRef<HTMLDivElement>(null);
  const paypalContainerId = 'paypal-button';

  const isVenmo = paymentMethod.mnemonic === PaymentTypes.Mnemonic.VENMO;
  const isPaypal = paymentMethod.mnemonic === PaymentTypes.Mnemonic.PAYPAL;

  // Initialize cart checking
  const { checkCart, needCartUpdate, prohibitedProductsInBasketRef } = usePaypalCartCheck({
    orderLines,
    subtotals,
  });

  // Initialize PayPal integration
  const { isPaypalSdkLoading, initializePaypal } = usePaypalIntegration({
    clientToken,
    paymentToken,
    orderLines,
    subtotals,
    cartId,
    onCheckCart: checkCart,
    prohibitedProductsInBasketRef,
  });

  useEffect(() => {
    if (window.processPaypal === 'RESET_PROCESS_ITEM') {
      dispatch(uiActions.setRefreshCartModal({ isOpen: needCartUpdate.current }));
      window.processPaypal = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needCartUpdate.current, dispatch]);

  const initializeBraintree = useCallback(() => {
    try {
      braintree.client.create(
        {
          authorization: clientToken,
        },
        async (clientErr, clientInstance) => {
          if (clientErr) {
            setBraintreeInitialized(false);
            logger.error('payment::braintree::clientInstance', clientErr);
            return;
          }

          setBraintreeInitialized(true);

          if (isPaypal && paypalContainerRef.current) {
            try {
              const { paypalCheckoutInstance, renderPaypalButtons } = await initializePaypal(clientInstance);
              const paypalButtons = renderPaypalButtons(paypalCheckoutInstance);
              paypalButtons.render(`#${paypalContainerId}`);
            } catch (error) {
              logger.error('payment::braintree::paypalInitialization', error);
            }
          }
        }
      );
    } catch (error) {
      setBraintreeInitialized(false);
      logger.error('payment::braintree::initializeBraintree', error);
    }
  }, [clientToken, isPaypal, initializePaypal]);

  useEffect(() => {
    initializeBraintree();
  }, [initializeBraintree]);

  const handleVenmoPayment = async () => {
    // Implement Venmo payment logic here
    console.log('handleVenmoPayment');
  };

  if (!clientToken || !isBraintreeInitialized) {
    return <PaymentButtonLoading text={t('PAYMENT.PAY_BUTTON', 'Submit order')} />;
  }

  return (
    <div data-testid="braintree-payment-button" className="braintreeContainer">
      {isPaypal && (
        <>
          <div ref={paypalContainerRef} id={paypalContainerId} className="paypalContainer" />
          {isPaypalSdkLoading && <PaymentButtonLoading text={t('PAYMENT.PAY_BUTTON', 'Submit order')} />}
        </>
      )}

      {isVenmo && (
        <button
          className="vc-btn vc-btn--primary vc-btn--large vc-btn--full"
          onClick={handleVenmoPayment}
          data-testid="venmo-payment-button"
        >
          {t('PAYMENT.PAY_BUTTON', 'Submit order')}
        </button>
      )}
    </div>
  );
};

// Add prop types validation if needed
BraintreePaymentButton.displayName = 'BraintreePaymentButton';

export default BraintreePaymentButton;
