// hooks/useCartCheck.ts
import { useCallback, useRef } from 'react';
import { OrderLine } from '../../../types';
import { SubtotalsTypes } from '../../subtotals';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { bucketTestSelectors } from '../../bucket-test';
import { cartActions, cartServices } from '../../cart';
import { uiActions } from '../../ui';
import logger from '../../../services/logger.service';

interface UseCartCheckProps {
  orderLines: OrderLine[];
  subtotals: SubtotalsTypes.State;
}

export const usePaypalCartCheck = ({ orderLines, subtotals }: UseCartCheckProps) => {
  const dispatch = useAppDispatch();
  const { checkCartUpdate } = useAppSelector(bucketTestSelectors.getFlags);
  const needCartUpdate = useRef(false);
  const prohibitedProductsInBasketRef = useRef<string[]>([]);

  const checkCart = useCallback(async (): Promise<boolean> => {
    return cartServices.api
      .check()
      .then((res) => {
        const prohibitedProductsIds = res.prohibition
          ?.filter((e) => e.prohibitedReason !== '')
          .map((e) => e.productId.toString());
        const clientAmount = subtotals.totalAmount.cents;
        const serverAmount = res.totalAmount?.cents;

        if (checkCartUpdate && clientAmount !== serverAmount) {
          window.processPaypal = 'RESET_PROCESS_ITEM';
          needCartUpdate.current = true;
          throw new Error('Cart needs update');
        }

        if (res.prohibition && prohibitedProductsIds?.length) {
          window.processPaypal = 'RESET_PROCESS_ITEM';
          const prohibitedProductOrderlines: string[] = orderLines
            .filter(({ product }) => prohibitedProductsIds.includes(product.id))
            .map((orderline) => orderline.id);
          prohibitedProductsInBasketRef.current = prohibitedProductOrderlines;
          return false;
        }

        if (res.needPassword) {
          window.processPaypal = 'RESET_PROCESS_ITEM';
          throw new Error('Password needed');
        }

        if (res.check) {
          prohibitedProductsInBasketRef.current = [];
          return true;
        }

        return false;
      })
      .catch((e) => {
        window.processPaypal = 'RESET_PROCESS_ITEM';
        if (e?.errors?.status === 409) {
          dispatch(
            uiActions.setMessageModal({
              isOpen: true,
              message: e.errors.detail,
            })
          );
          const errorType = e?.meta?.type || 'unknown-error-type';
          logger.error(`cart::checkCart::${errorType}`, { paymentMethod: 'paypal', error: e });
        } else {
          if (!prohibitedProductsInBasketRef.current.length) {
            window.processPaypal = undefined;
          }
          logger.error('cart::checkCart::rejected', { paymentMethod: 'paypal', error: e });
          dispatch(cartActions.checkReject());
        }
        throw e;
      });
  }, [checkCartUpdate, dispatch, orderLines, subtotals.totalAmount.cents]);

  return {
    checkCart,
    needCartUpdate,
    prohibitedProductsInBasketRef,
  };
};
